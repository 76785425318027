// import { lazy } from "react";
import React, { lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import Layout from "./Layout/Layout";
import "./index.css";
// import Login from "./pages/Login/Login";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import checkAuth from "./auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Login = lazy(() => import('./pages/Login/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'))
const Layout = lazy(() => import('./Layout/Layout'))


const token = checkAuth()

const App = () => {

  // useEffect(() => {
  //   console.log("Token:", token);
  // }, [token]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Place new routes over this */}
        <Route path="/app/*" element={<Layout />} />

        <Route
          path="*"
          element={
            <Navigate to={token ? "/app/dashboard" : "/login"} replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
